import { INavData } from '@coreui/angular-pro';
import { featureFlags } from '../../config/features';

export const navItems: INavData[] = [
  {
    title: true,
    name: 'Launch Pad',
  },
  {
    name: 'Consumer Profile',
    url: '/cp',
    iconComponent: { name: 'cilPeople' },
    badge: {
      color: 'info',
      text: 'START'
    }
  },
  {
    title: true,
    name: 'Create Product',
  },
  {
    name: 'InnovationGPT',
    url: '/cpi',
    iconComponent: { name: 'cidGraph', color: 'info' },
    attributes: { disabled: !featureFlags.innovation }
  },
  {
    title: true,
    name: 'Create Advertising',
  },
  {
    name: 'adGPT',
    url: '/ads',
    iconComponent: { name: 'cisTv', color: 'info'},
    attributes: { disabled: !featureFlags.ads }
  },
  {
    name: 'Messaging',
    url: '/messaging',
    iconComponent: { name: 'cilLightbulb', color: 'info'},
    attributes: { disabled: !featureFlags.messaging }
  },
  {
    name: 'Microoccasions',
    url: '/micro',
    iconComponent: { name: 'cilCalendar', color: 'info'},
    attributes: { disabled: !featureFlags.microoccasions }
  },
  {
    name: 'Microfestivals',
    url: '/microfestivals',
    iconComponent: { name: 'cilCalendar', color: 'info'},
    attributes: { disabled: !featureFlags.microfestivals }
  },
  {
    name: 'Bulk Messaging',
    url: '/bulkstatus',
    iconComponent: { name: 'cilBookmark', color: 'info'},
    attributes: { disabled: !featureFlags.bulkmessaging }
  },
  {
    title: true,
    name: 'Analyze and Create Desire',
  },
  {
    name: 'Score',
    url: '/desire',
      iconComponent: { name: 'cilHeart' },
    attributes: { disabled: !featureFlags.desire.score }
  },
  {
    name: 'Rewrite',
    url: '/desirerewrite',
    iconComponent: { name: 'cilPen' },
    attributes: { disabled: !featureFlags.neuro.neuroadrewrite }
  },
  {
    title: true,
    name: 'Add Fragrance',
  },
  {
    name: 'Design',
    url: '/frd',
    iconComponent: { name: 'cilBeaker' },
    attributes: { disabled: !featureFlags.fragrance.design }
  },
  {
    name: 'Compositions',
    url: '/blend',
    iconComponent: { name: 'cilPen' },
    attributes: { disabled: !featureFlags.fragrance.compositions }
  },
  {
    title: true,
    name: 'Add Flavor', 
  },
  {
    name: 'Staging',
    url: '/frs',
    iconComponent: { name: 'cilRestaurant' },
    attributes: { disabled: !featureFlags.flavor.staging },
  },
  {
    name: 'Cook',
    url: '/flc',
    iconComponent: { name: 'cilDinner' },
    attributes: { disabled: !featureFlags.flavor.cook },
  },
  {
    title: true,
    name: 'Analyze and Align with Neuroscience',
  },
  {
    name: 'neuroScore',
    url: '/neuro',
    iconComponent: { name: 'cibCreativeCommonsSampling' },
    attributes: { disabled: !featureFlags.neuro.score },
  },
  {
    name: 'Rewrite',
    url: '/neuroadrewrite',
    iconComponent: { name: 'cilPen' },
    attributes: { disabled: !featureFlags.neuro.neuroadrewrite },
  },
  {
    title: true,
    name: 'Create Music and Sonic Branding',
  },    
  {
    name: 'Studio',
    url: '/musiccompose',
    iconComponent: { name: 'cisMusicNoteAlt' },
    attributes: { disabled: !featureFlags.musicStudio },
  },
  {
    name: 'Compositions',
    url: '/musicgen',
    iconComponent: { name: 'cilMediaPlay' },
    attributes: { disabled: !featureFlags.music.compositions },
  },
  {
    title: true,
    name: 'Create a Brand',
  },
  {
    name: 'Brand Book',
    url: '/celerybranddesign',
    iconComponent: { name: 'cidTrademark' },
    attributes: { disabled: !featureFlags.brand.design },
  },
  {
    name: 'CBM',
    url: '/cbm',
    iconComponent: { name: 'cilChartPie' },
    attributes: { disabled: !featureFlags.brand.book },
  },
  {
    name: 'Brand Match',
    url: '/bm',
    iconComponent: { name: 'cidTrademark' },
    attributes: { disabled: !featureFlags.brand.match },
  },
  {
    title: true,
    name: 'Non-Conscious Pricing',
  },
  {
    name: 'priceGPT',
    url: '/pr',
    iconComponent: { name: 'cilDollar' },
    attributes: { disabled: !featureFlags.pricing },
  },
  {
    title: true,
    name: 'Baseline',
  },
  {
    name: 'Baseline',
    url: '/bl',
    iconComponent: { name: 'cilSpreadsheet' },
    attributes: { disabled: !featureFlags.baseline },
  },
  {
    name: 'Promotions',
    url: '/pro',
    iconComponent: { name: 'cilCreditCard' },
    attributes: { disabled: !featureFlags.promotions },
  },
  {
    title: true,
    name: 'Dashboard',
  },
  {
    name: 'Projects',
    url: '/db',
    iconComponent: { name: 'cilGrid' },
  },
];