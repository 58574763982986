export const featureFlags = {
    musicStudio: true,
    musicCompositions: true,
    pricing: true,
    baseline: true,
    promotions: true,
    innovation: true,
    ads: true,
    messaging: true,
    microoccasions: true,
    microfestivals: true,
    bulkmessaging: true,
    desire: {
      score: true,
      rewrite: true
    },
    neuro: {
      score: true,
      neuroadrewrite: true
    },
    fragrance: {
      design: true,
      staging: true,
      compositions: true,
      cook: true
    },
    flavor: {
      design: true,
      staging: true,
      cook: true
    },
    music: {
      studio: true,
      compositions: true
    },
    brand: {
      design: true,
      book: true,
      match: true
    },
  };